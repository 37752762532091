import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
import HubspotForm from "react-hubspot-form"

const Apply = ({ location }) => {
  return (
    <Layout>
      <Seo
        pagetitle="お申し込み"
        pagepath={location.pathname}
        pagedesc="お申し込み | PARKLoT(パークロット)は「月額制」で「何回でも」実施できるTwitterキャンペーンツールを提供しています。"
      />
      <Breadcrumb title="お申し込み" />
      <main className="information">
        <section className="information__section l-container">
          {/* <h1 className="information__title"></h1> */}
          <HubspotForm
            portalId="8283520"
            formId="cd6d1560-bdc7-4169-9c55-640cc2a2500c"
            loading={<div>読み込んでいます...</div>}
          />
        </section>
      </main>
    </Layout>
  )
}

export default Apply
